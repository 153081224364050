<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <p><b-link :to="{ name: 'Faqs' }">all FAQs</b-link><b-button class="mr-2 ml-2" v-if="permission.edit && step.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'faq' }}">Edit</b-button></p>
                <h2 class="mb-4">
                  {{item.question}}
                </h2>
                <h4 v-if="item.fact">Fact pattern</h4>
                <div v-html="item.fact" class="mb-5"></div>
                <h4 v-if="item.answer">Answer</h4>
                <div class="mb-5" style="font-size: 1.2em; font-weight: 500">
                  {{item.answer}}
                </div>
                <h4 v-if="item.long">Long Answer</h4>
                <div v-html="item.long" class="mb-5"></div>
                <div><small>{{item.created_by}} created on {{moment(item.created_at).format('YYYY-MM-DD')}}</small></div>
                <div><small>{{item.updated_by}} last updated on {{moment(item.updated_at).format('YYYY-MM-DD')}}</small></div>
              </b-col>
              <b-col>
                <h4>Workflow</h4>
                <div>status: <strong>{{step.name}}</strong></div>
                <div v-if="item.status_created_by"><small>{{item.status_created_by}} at {{moment(item.status_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
                <div>
                  <span v-if="!savingStep && permission.edit">
                    <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
                    <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
                  </span>
                  <span v-if="savingStep">
                    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                  </span>
                </div>
                <h4 class="mt-5">Regulations</h4>
                <div style="font-size: 1.2em">
                  {{item.regulations}}
                </div>
                <h4 class="mt-5">Insights</h4>
                <div style="font-size: 1.2em">
                  {{item.insights}}
                </div>
                <h4 class="mt-5">Opportunity</h4>
                <div style="font-size: 1.2em">
                  {{item.opportunity}}
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/faq'

export default {
  name: 'Faq',
  computed: {
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).readAny('faqWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).createAny('faq').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).readAny('faqWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).createAny('faq').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.status_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'control', action: 'open control', model: 'control', model_id: this.$route.params.id })
    this.permission.read = ac.can(this.user.acgroups).readAny('faq').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('faq').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      moment: moment,
      item: null,
      permission: {
        edit: false,
        read: false
      },
      savingStep: false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const apiName = 'cosmos'
        const path = `/faqs/faq/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            status_id: id,
            status_created_at: moment().toISOString(),
            status_created_by: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/faq/${this.item.id}`, params)
        this.item.status_id = id
        this.item.status_created_at = params.body.status_created_at
        this.item.status_created_by = params.body.status_created_by
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>

</style>
